import { LoginResponse } from "passemix-lib/src/models/LoginResponse";
import { SessaoCliente } from "passemix-lib/src/models/SessaoCliente";

export class GlobalService
{
    public static getSessao(): SessaoCliente
    {
        let sessao = new SessaoCliente();
        let storage = sessionStorage.getItem('sessionAuth');

        if(storage !== null && storage !== undefined)
            sessao = JSON.parse(storage);

        return sessao;
    }

    public static setSessaoCliente(sessao: SessaoCliente)
    {
        sessionStorage.setItem('sessionAuth', JSON.stringify(sessao));
        return sessao;
    }

    public static setSessao(sessao: LoginResponse)
    {
        sessionStorage.setItem('sessionAuth', JSON.stringify(sessao));
        return sessao;
    }

    public static closeSessao(): void
    {
        sessionStorage.clear();
    }

    public static isAutenticado(): boolean
    {
        let sessao = this.getSessao();

        if(sessao === null || sessao === undefined || sessao.accessToken === undefined || sessao.accessToken === '')
        {
            return false;
        }
        else
        {
            return true;
        }
    }

}