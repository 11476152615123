import React from "react";
import { Carousel } from "primereact/carousel";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import produtosVenda from "../../content/img/produtos-venda.png";
import produtor from "../../content/img/produtor.png";
import logoMeta from "../../content/img/meta-logo.png";
import googleAds from "../../content/img/google-adwords-logo.png";
import banner_01 from "../../content/img/banner/banner_01.png";
import banner_02 from "../../content/img/banner/banner_02.png";
import banner_03 from "../../content/img/banner/banner_03.png";
import servicos_desktop from "../../content/img/servicos_desktop.png";
import servicos_mobile from "../../content/img/servicos_mobile.png";

export interface IHomeComponent {}

const HomeComponent: React.FC<IHomeComponent> = (props) => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const banners = [
    { image: banner_03 },
    { image: banner_02 },
    { image: banner_01 },
  ];

  const productTemplate = (banner) => {
    return (
      <img src={banner.image} alt="banner" className="img-banner-carousel" />
    );
  };

  const abrirContato = () => {
    window.open("https://wa.me/553597760956", "_blank");
  };

  return (
    <>
      <div className="p-mt-5 text-roboto">
        <Carousel
          value={banners}
          numVisible={1}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          className="banner-carousel p-d-none p-d-lg-inline p-d-xl-inline"
          circular
          autoplayInterval={5000}
          itemTemplate={productTemplate}
          showIndicators={false}
        />
        <div className="p-grid p-m-0 p-p-5">
          <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6 p-mb-6 p-text-center">
            <img
              src={produtosVenda}
              alt="produtos para vendas"
              className="lp-image-section"
            />
          </div>
          <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6 p-mb-6 p-pt-6">
            <span className="lp-title-section">CONTROLE TOTAL</span>
            <br />
            <span className="lp-subtitle-section">SOBRE AS SUAS VENDAS</span>
            <ul className="lp-text-section">
              <li>Ingressos online e presenciais personalizados</li>
              <li>Fichas personalizadas</li>
              <li>Relatórios personalizados e em tempo real</li>
            </ul>
            <button className="lp-button-contato" onClick={abrirContato}>
              Fale com nosso time!
            </button>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6 p-mb-4 p-pt-6 p-text-right">
            <span className="lp-title-section">SEGURANÇA E TECNOLOGIA</span>
            <br />
            <span className="lp-subtitle-section">SOBRE AS SUAS VENDAS</span>
            <ul className="lp-text-section">
              <li>Gestão do seu evento em um portal exclusivo</li>
              <li>Validação de ingressos por meio do nosso aplicativo</li>
              <li>Impressão antifraude de ingresso com itens de segurança</li>
            </ul>
          </div>
          <div className="p-col-12 p-sm-12 p-lg-6 p-xl-6 p-mb-4 p-text-center">
            <img
              src={produtor}
              alt="painel administrativo"
              className="lp-image-section"
            />
          </div>
          <div className="p-col-12 p-mb-4 p-mt-6">
            <div className="p-text-center">
              <span className="lp-title-section">
                INFORMAÇÕES ATUALIZADAS EM TEMPO REAL
              </span>
              <br />
              <span className="lp-subtitle-section">
                AUTONOMIA PARA A SUA TOMADA DE DECISÃO
              </span>
            </div>
            <div className="p-text-center p-mt-4">
              <button className="lp-button-contato" onClick={abrirContato}>
                Fale com nosso time!
              </button>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-pt-4 p-pb-4 solucoes p-text-center p-mt-3">
          <div className="p-grid">
            <div className="p-col-12 p-d-none p-d-md-inline-flex">
              <img src={servicos_desktop} alt="banner" className="img-fluid" />
            </div>
            <div className="p-col-12 p-d-md-none">
              <img src={servicos_mobile} alt="banner" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="p-col-12 p-text-center p-pt-6 p-pb-6">
          <h1>
            <span className="font-roboto-regular">
              Traga qualidade e segurança para seu evento.
            </span>
            <br /> Comece a vender agora mesmo!
          </h1>
          <div className="mt-5">
            <a
              href="https://api.whatsapp.com/send/?phone=553597760956"
              target="_blank"
              rel="noreferrer"
              className="btn button-y button-lg font-bold"
            >
              CADASTRAR MEU EVENTO
            </a>
          </div>
        </div>
        <div className="p-col-10 p-offset-1 p-mt-4 p-mb-4 lp-ads">
          <div className="p-text-center">
            <span className="lp-title-section">
              EVOLUA NA FORMA DE DIVULGAR SEUS EVENTOS!
            </span>
            <br />
            <span className="lp-subtitle-section">
              IMPLEMENTAÇÃO DO SEU PIXEL LIBERADA
            </span>
          </div>
          <div className="p-text-center p-mt-4">
            <img src={logoMeta} alt="logo meta" className="lp-image-ads" />
            <img
              src={googleAds}
              alt="logo google ads"
              className="lp-image-ads"
            />
          </div>
        </div>
        <a
          href="https://api.whatsapp.com/send/?phone=5535998768359"
          target="_blank"
          rel="noreferrer"
          className="botao-whatsapp"
        >
          <FontAwesomeIcon icon={faWhatsapp} style={{ marginTop: "14px" }} />
        </a>
      </div>
    </>
  );
};

export default HomeComponent;
